// showHideSidebar
function showHideSidebar() {
  // if (!document) return;

  // let element = document.getElementById('accordionSidebar')

  // // Set SidebarNoExpand to sessionStorage
  // if (element.classList.toggle('sidebarNoExpand')) {
  //   sessionStorage.setItem('pi-ui-sidebar-no-expand', 'sidebarNoExpand')
  // } else {
  //   sessionStorage.setItem('pi-ui-sidebar-no-expand', 'sidebarExpand')
  // }
}
